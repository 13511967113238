import React from 'react'
import { Container } from 'react-bootstrap'
import { Player } from '../player/Player'
import { Programming } from '../programming/Programming'

export const DashBoard = () => {
  return (
    <>
      <Programming />

      <Container>
        <div className="row my-5">
          <div className="col-12 d-flex justify-content-center">
            <Player />
          </div>
        </div>
      </Container>
    </>
  )
}
