import React from 'react'
import { DashBoard } from './components/dashBoard/DashBoard'
import { AppRouter } from './routes/AppRouter'

const App = () => {
  return (
    <AppRouter>
      <DashBoard />
    </AppRouter>
  )
}

export default App