import React from 'react'

export const Footer = () => {

    const fbigl = () => {
        window.open('https://www.facebook.com/ccbsbonao')
    }

    const fbrd = () => {
        window.open('https://www.facebook.com/radiobonaobendicio')
    }

    const fbIns = () => {
        window.open('https://www.instagram.com/ccbsbonao/?hl=es-la')
    }

    const fbiYT = () => {
        window.open('https://www.youtube.com/c/Centrocristiano100')
    }

    const ccbs = () => {
        window.open('https://www.ccbsbonao.com.do')
    }
    return (
        <>
            <footer className="shadow p-4 bg-dark rounded-lg flex-column text-white pt-5">

                <div className="container text-center text-md-left">

                    <div className="row text-center text-md-left">

                        <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                            <h5 className="text-uppercase mb-4 font-weight-bold" style={{color: 'gold'}}>Centro Cristiano El Buen Samaritano</h5>
                            <p>Somos una gran familia, unida para amar y servir a Dios, comprometidos con alcanzar el perdido y equiparlo hasta que tenga el carácter de Cristo.</p>
                            
                        </div>

                        <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                            <h5 className="text-uppercase mb-4 font-weight-bold" style = {{color: 'gold'}}>Nuestros Links</h5>
                        <p style={{cursor: 'pointer'}}>
                            <span onClick={fbigl} className="text-white" style={{textDecoration: "none"}}> Facebook De La Iglesia</span>
                        </p>
                        <p style={{cursor: 'pointer'}}>
                            <span onClick={fbrd} className="text-white" style={{textDecoration: "none"}}> Facebook De Radio Bonao Bendición</span>
                        </p>
                        <p style={{cursor: 'pointer'}}>
                            <span onClick={fbIns} className="text-white" style={{textDecoration: "none"}}> Instagram</span>
                        </p>
                        <p style={{cursor: 'pointer'}}>
                            <span onClick={fbiYT} className="text-white" style={{textDecoration: "none"}}> Youtube</span>
                        </p>

                        <p style={{cursor: 'pointer'}}>
                            <span onClick={ccbs} className="text-white" style={{textDecoration: "none"}}> Página De La Iglesia</span>
                        </p>
                        </div>

                        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                            <h5 className="text-uppercase mb-4 font-weight-bold" style = {{color: 'gold'}} >Contacto</h5>
                            <p>
                                <i className="fas fa-home mr-3"></i> República Dominicana, Bonao, Padre Fantino.
                            </p>
                            <p>
                                <i className="fas fa-envelope mr-3"></i> centrocristiano777@gmail.com
                            </p>
                            <p>
                                <i className="fas fa-phone mr-3"></i> +1 (809)-296-1771
                            </p>
                        </div>
                        
                    </div>

                    <hr className="mb-4" />

                    <div className="row align-items-center">

                        <div className="col text-center">
                            <p>	Copyright ©2022 All rights reserved by:
                                <span style={{textDecoration: "none"}}>
                                    <strong> Telebendicion</strong>
                                </span></p>
                            
                        </div>
                        
                    </div>

                </div>

            </footer>

        </>
    )
}
