import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas'
import { NavLink } from 'react-router-dom';
import TELEBENDICION1 from '../../media/logo512.png'
import './Sidebar.css'
import ccbs from '../../media/LOGO-CCBS.ico'

export const Sidebar = () => {

    const [show, setShow] = useState(false);
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const RedirectToFb = () => {
        window.open('https://www.facebook.com/TelebendicionRD')
    }

    const RedirectToYT = () => {
        window.open('https://www.youtube.com/channel/UC3hoNcGbeyEEB2ll3gy8heQ')
    }

    const RedirectToCCBS = () => {
        window.open('https://www.ccbsbonao.com.do/Home')
    }
    
    return (
        <>
            <i id='logo-list' style = {{cursor: 'pointer', color: 'white', fontSize: '25px'}} onClick={handleShow} className="bi bi-list"></i>

            <Offcanvas placement='end' show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                <Offcanvas.Title>Dios te bendiga</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>

                    <div className='d-flex justify-content-center align-items-center'>
                        <img src={TELEBENDICION1} className='img-fluid' style={{width: 'auto', height: '150px'}} alt="" />
                    </div>

                    <ul className="list-group list-group-flush text-center mt-3">
                        <NavLink to = '/Telebendicion' className = 'list-group-item decoration-line list-focus' activeClassName = 'true'>En vivo</NavLink>
                        <NavLink to = '/Programacion' className = 'list-group-item decoration-line list-focus' activeClassName = 'true'>Programación</NavLink>
                        <NavLink to = '/Canales' className = 'list-group-item decoration-line list-focus' activeClassName = 'true'>Canales</NavLink>
                    </ul>

                    <div className='my-5' style={{display: 'flex', justifyContent: 'center'}}>
                        <div className = 'btn d-flex justify-content-center align-items-center' style={{margin: 0, padding: 0}}>
                            <i onClick={RedirectToFb} style={{fontSize: '30px', color: '#3b5998', borderRadius: '50px', background: 'white', height: '27px', width: '27px'}} className="fa-brands fa-facebook d-flex justify-content-center align-items-center mx-2"></i>
                        </div>

                        <div className = 'btn d-flex justify-content-center align-items-center mx-2' style={{margin: 0, padding: 0}}>
                            <i onClick={RedirectToYT} style={{fontSize: '30px', color: 'red', background: 'white', height: '20px', width: '20px'}} className="fa-brands fa-youtube d-flex justify-content-center align-items-center mx-2"></i>
                        </div>

                        <div className = 'btn d-flex justify-content-center align-items-center' style={{margin: 0, padding: 0}}>
                            <img onClick={RedirectToCCBS} style = {{height: '40px', width: '40px'}} src={ccbs} alt="" />
                        </div>
                    </div>

                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}
