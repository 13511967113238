import React from 'react'

export const Player = () => {
  return (
    <iframe
      title='Telebendicion'

      width='800px' height="450px" frameBorder="0"

      style={{borderRadius: '12px'}}

      allowfullscreen allow-same-origin allow-scripts allow="fullscreen;"

      src="https://dominicanplayers.com/video-player/346/0">

    </iframe>
  )
}
