import { Container, Nav, Navbar } from 'react-bootstrap'
import './Navb.css'
import ccbs from '../../media/LOGO-CCBS.ico'
import TELEBENDICION1 from '../../media/logo512.png'
import { NavLink } from 'react-router-dom'
import { Sidebar } from '../sidebar/Sidebar'
import { useResponsive } from '../../hooks/useResponsive'

export const Navb = () => {

    const RedirectToFb = () => {
        window.open('https://www.facebook.com/TelebendicionRD')
    }

    const RedirectToYT = () => {
        window.open('https://www.youtube.com/channel/UC3hoNcGbeyEEB2ll3gy8heQ')
    }

    const RedirectToCCBS = () => {
        window.open('https://www.ccbsbonao.com.do/Home')
    }

    const [ respWidth ] = useResponsive()
    
    return (
        <Navbar id='navBakcground' bg = 'dark' variant="dark">
            <Container>
                <Navbar.Brand style = {{cursor: 'pointer', margin: 0, padding: 0}}>
                    <span className = 'Navb-tittle d-flex justify-content-center align-items-center'>
                        <img src={TELEBENDICION1} id = 'no-image' className='img-fluid' style={{width: 'auto', height: '50px'}} alt="" />
                        <Nav.Item id='tituloNav' style = {{textDecoration: 'none', color: 'white', margin: 0, padding: 0}} to = '/Dashboard'>TELEBENDICIÓN</Nav.Item>
                    </span>
                </Navbar.Brand>

                {
                    (respWidth > 992)
                        &&
                    <Nav className="mx-auto">

                        <NavLink to='/Telebendicion' className='nav-link'>En vivo</NavLink>

                        <NavLink to='/Programacion' className='nav-link'>Programación</NavLink>

                        <NavLink to='/Canales' className='nav-link'>Canales</NavLink>

                    </Nav>
                }

                {
                    (respWidth > 992)
                        &&
                    <Nav>
                        <div className = 'btn d-flex justify-content-center align-items-center' style={{margin: 0, padding: 0}}>
                            <i onClick={RedirectToFb} style={{fontSize: '30px', color: '#3b5998', borderRadius: '50px', background: 'white', height: '27px', width: '27px'}} className="fa-brands fa-facebook d-flex justify-content-center align-items-center mx-2"></i>
                        </div>

                        <div className = 'btn d-flex justify-content-center align-items-center mx-2' style={{margin: 0, padding: 0}}>
                            <i onClick={RedirectToYT} style={{fontSize: '30px', color: 'red', background: 'white', height: '20px', width: '20px'}} className="fa-brands fa-youtube d-flex justify-content-center align-items-center mx-2"></i>
                        </div>

                        <div className = 'btn d-flex justify-content-center align-items-center' style={{margin: 0, padding: 0}}>
                            <img onClick={RedirectToCCBS} style = {{height: '40px', width: '40px'}} src={ccbs} alt="" />
                        </div>
                    </Nav>
                }


                {
                    (respWidth < 992)
                        &&
                    <Nav>
                        <Sidebar />
                    </Nav>
                }


            </Container>
        </Navbar>
    )
}
