import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';
import { fetchSinToken } from '../../helpers/fetch'

export const Programming = () => {

  var settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      }
    ]
  };

  const [imagen, setImagen] = useState()

  const startGetImages = async() => {

    const resp = await fetchSinToken('bannerImage')
    const body = await resp.json()

    if (body.ok) {
      setImagen(body.banner)
    }
  }

  useEffect(() => {
    startGetImages()
  }, [])
  
  return (
    <>
      <Slider arrows = {false} {...settings}>
        {
          imagen?.map(imagen => {
            return (

              <img
                key={imagen?._id}
                  style={{height: '100%', width: '100%', objectFit: 'cover'}}
                  // className="image-round img-fluid"
                  src = {imagen.image}
                  alt="..."
              />
              )
          })
        }
      </Slider>
    </>
  )
}
