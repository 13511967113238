import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import { ChannelsPage } from '../components/channels/ChannelsPage'
import { DashBoard } from '../components/dashBoard/DashBoard'
import { Footer } from '../components/footer/Footer'
import { Navb } from '../components/navbar/Navb'
import { ProgrammingPage } from '../components/ProgrammingPage/ProgrammingPage'

export const AppRouter = () => {
  return (
    <Router>
        <Navb />
          <Switch>
              <Route path='/Telebendicion' component={DashBoard} />

              <Route path='/Programacion' component={ProgrammingPage} />

              <Route path='/Canales' component={ChannelsPage} />

              <Redirect to='/Telebendicion' />
          </Switch>
        <div>
            <Footer />
        </div>
    </Router>
  )
}
