import React from 'react'
import { Container } from 'react-bootstrap'
import Amarillo from '../../media/Amarrillo.webp'

export const ChannelsPage = () => {
  return (
    <Container>

      <div className="p-4 my-5" style={{backgroundImage: `url(${Amarillo})`, borderRadius: '12px'}}>
        <div className='bg-dark shadow image-round p-4 flex-column'>
          <h1 className='text-white text-center'>En el Cibao, Telebendicion se ve en los siguientes canales:</h1>
          <div className="row mt-5">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <h3 className='text-white text-center'>
                Canal 464 Santiago (Telecable Central) 
              </h3>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <h3 className='text-white text-center'>
                Canal 464 Las Canelas
              </h3>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <h3 className='text-white text-center'>
                (Telecable Central) 
              </h3>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <h3 className='text-white text-center'>
                Canal 464 La Vega (Telecable Central)
              </h3>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <h3 className='text-white text-center'>
                Canal 464 Jima Abajo (Telecable Central) 
              </h3>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <h3 className='text-white text-center'>
                Canal 464 Villa Tapia
              </h3>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <h3 className='text-white text-center'>
                (Telecable Central) 
              </h3>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <h3 className='text-white text-center'>
                Canal 278 Monte Cristi (Cable Visión)
              </h3>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <h3 className='text-white text-center'>
                Canal 464 Pto. Plata (Telecable Central)
              </h3>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <h3 className='text-white text-center'>
                Canal 202 Bonao, Maimon y Piedra Blanca (Telecasa)
              </h3>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}
