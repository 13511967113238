import React from 'react'
import { Modal } from 'react-bootstrap'
import Lunes from './Lunes.json'
import Martes from './Martes.json'
import Miercoles from './Miercoles.json'
import Jueves from './Jueves.json'
import Viernes from './Viernes.json'
import Sabado from './Sabado.json'
import Domingo from './Domingo.json'
import { useEffect } from 'react'
import { useState } from 'react'

export const ModalPrograming = ({showModal, setShowModal, handleDay}) => {

    const handleClose = () => {
        setShowModal(false)
    }

    const [newArreglo, setNewArreglo] = useState([])

    useEffect(() => {
      if (handleDay === 'Lunes') {
        setNewArreglo(Lunes)
      }

      if (handleDay === 'Martes') {
        setNewArreglo(Martes)
      }

      if (handleDay === 'Miércoles') {
        setNewArreglo(Miercoles)
      }

      if (handleDay === 'Jueves') {
        setNewArreglo(Jueves)
      }

      if (handleDay === 'Viernes') {
        setNewArreglo(Viernes)
      }

      if (handleDay === 'Sabado') {
        setNewArreglo(Sabado)
      }

      if (handleDay === 'Domingo') {
        setNewArreglo(Domingo)
      }
    }, [handleDay])

  return (
    <Modal fullscreen show={showModal} onHide={handleClose}>
        <Modal.Header style={{border: 'none'}} closeButton>
          <Modal.Title>{handleDay}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row" style={{height: '500px', overflow: 'auto'}}>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    <div className='table-responsive'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Hora</th>
                                    <th scope="col">Programa</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    newArreglo?.Am?.map(({Hora, Programa}) => {
                                        return (
                                            <tr>
                                                <td>{Hora} AM</td>
                                                <td>{Programa}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    <div className='table-responsive'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Hora</th>
                                    <th scope="col">Programa</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    newArreglo?.Pm?.map(({Hora, Programa}) => {
                                        return (
                                            <tr>
                                                <td>{Hora} PM</td>
                                                <td>{Programa}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
  )
}
