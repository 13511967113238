import React from 'react'
import { useState } from 'react'
import { Container } from 'react-bootstrap'
import { ModalPrograming } from './ModalPrograming'
import Amarillo from '../../media/Amarrillo.webp'

export const ProgrammingPage = () => {

  const [showModal, setShowModal] = useState(false)

  const dayWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo']

  const [handleDay, setHandleDay] = useState('')

  const handledDay = (day) => {
    setShowModal(true)
    setHandleDay(day)
  }

  return (
    <Container>
      <h3 className='text-white'>Horario de programación</h3>

      <div className="p-4 mb-5 mt-3" style={{backgroundImage: `url(${Amarillo})`, borderRadius: '12px'}}>
        <div className='bg-dark shadow p-4 flex-column image-round'>
          <div className='d-flex' style = {{overflowX: 'auto', justifyContent: 'space-between'}}>
            {
              dayWeek.map(day => {
                return (
                  <button onClick={() => handledDay(day)} className='mx-2 btn' style={{backgroundColor: 'rgb(59, 89, 152)', color: 'white'}}>{day}</button>
                )
              })
            }
          </div>
        </div>
      </div>

      {/* <div className="row">
        <h3 className='text-white'>Videos promocionales</h3>
        <div className="col-12">
          <Carrousel />
        </div>
      </div> */}

      {
        (showModal)
          &&
        <ModalPrograming 
          showModal = {showModal}
          setShowModal = {setShowModal}
          handleDay = {handleDay}
        />
      }

    </Container>
  )
}
